import React ,{ useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { connect } from 'react-redux'
import { setMainLayers, setShowGrid, setBoothNumberLayer, setBoothOutlineLayer, setAnnotationLayer, setOriginalLayer, setExhibitorList } from '../../../actions/main'
import { setLeftPage, setLeftSelectedCategory } from '../../../actions/ui'
import { setOpenLogin, setLeftBoothList, setBookmardUpdated, setOpenBoothList, setOpenBoothDrawer, setExhibitorSearchKey } from '../../../actions/ui'
import LinkIcon from '@mui/icons-material/Link';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ThreeDRotationOutlinedIcon from '@mui/icons-material/ThreeDRotationOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { setLoading } from '../../../actions/main'
import qs, { parse } from 'qs'
import axios from 'axios';
import axiosInstance from '../../../utils/axiosInstance.js'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { parseToken, listToTree, download } from '../../../utils/Common'
import MenuIcon from '@mui/icons-material/Menu';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import BoothDrawer from './BoothDrawer';
import CompanyEditModal from '../CompanyEditModal'
import { setActiveObject } from '../../../utils/CanvasTool'
import { getTokenCookie, getTokenPlanner, getUrlToken } from '../../../utils/Common'
import Exhibitors from "./Exhibitors"
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { API_URL, S3_URL, ESHOW_URL } from '../../../config.js'
import SearchBox from './SearchBox.js';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Index(props) {
  const { onClose, selectedValue, open } = props;
  const [boothList, setBoothList] = useState([]);
  const [filteredBoothList, setFilteredBoothList] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState("");
  const [editBooth, setEditBooth]  = React.useState(null);
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  let query = useQuery();
  var mainLayers = props.main.mainLayers;
  const htmlElRef = React.useRef(null)

  const main_widow_offset_x = 0;
  const main_widow_offset_y = 51;

  const useStyles = makeStyles((theme) => ({
    navItem: {
      color: props.ui?.customStyles?.['left-drawer-icon-color']?(props.ui?.customStyles['left-drawer-icon-color']+"!important"):'rgba(0, 0, 0, 0.54)',
      "&:hover": {
        color: props.ui?.customStyles?.['left-drawer-icon-color']?(props.ui?.customStyles['left-drawer-icon-color']+"!important"):'rgba(0, 0, 0, 0.54)',
      },
      "&.active": {
        color: props.ui?.customStyles?.['left-drawer-icon-color']?(props.ui?.customStyles['left-drawer-icon-color']+"!important"):'rgba(0, 0, 0, 0.54)',
      },
    },
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    }
  }));

  const classes = useStyles();

  const getBoothList = ()=>{
    setLoadingFlag(true)
    setLoaded(false)
    var active_booth_file = props.main.active_booth_file
    var token = getTokenCookie();   
    var map_key;   
    if(props.main.authInfo?.role != 'admin') {
      try{     
          var _token = getTokenPlanner();
          if(_token){
            token = _token
          }
      }
      catch(e){
        console.log("token parse", e)
      }
    }
    else{
    }
    map_key = active_booth_file?.KEY_ID
    if(query.get("map")){
      map_key = query.get("map");
    }
    map_key=false
    var url = `${ESHOW_URL()}?method=getExhibitorList&token=${token}${map_key?('&map_key='+map_key):''}`
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
            props.setExhibitorList(result)
            if(result.EXHIBITORS){
              var boothList = [];
              for(var i = 0; i< result.EXHIBITORS.length; i++){
                var item = result.EXHIBITORS[i]
                if(!item?.SETUP?.LIST_PENDING_EXHIBITORS && item.STATUS == 2)
                  continue;
                boothList.push(
                  {
                    booth: item['BOOTHS']?item['BOOTHS']:"",
                    BOOTHS: item['BOOTHS']?item['BOOTHS']:"",
                    name:item['BOOTHS']?item['BOOTHS']:"",
                    priced:false,
                    id:"",
                    EXHIBITOR_KEY:item['EXHIBITOR_KEY']?item['EXHIBITOR_KEY']:"",
                    company:item['COMPANY_NAME']?item['COMPANY_NAME']:"",
                    bookmark:item['BOOKMARKED']?item['BOOKMARKED']:"",
                  }
                )
              }            
              boothList.sort(function(a, b) {
                const nameA = a.company+" "+a.name; // ignore upper and lowercase
                const nameB = b.company+" "+a.name; // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              
                // names must be equal
                return 0;
              });
              boothList.sort(function(a, b) {
                return a.company - b.company;
              });         
              setBoothList(boothList)
            }
            setLoadingFlag(false)
            setLoaded(true)
        }
        else{
          setLoadingFlag(false)
        }
    }).catch(error => {
      setLoadingFlag(false)
    })  
  }

  useEffect(() => {
    if(props.open){
      props.setOpenBoothDrawer(true)
      setSearchVal("")
    }
  }, [props.open])

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var show_key = urlParams.get('show_key')  
  var urlToken = getUrlToken()   
  useEffect(() => {
    if(props.ui.openBoothList && props.ui.leftPage == "home"){
      getBoothList();
    }
  }, [props.main[`planner-${urlToken}`]])
  
  useEffect(() => {
    if(props.ui.openBoothList && props.ui.leftPage == "home"){
      getBoothList();
    }
  }, [query])


  const exportPdf = ()=>{
    var mainSvg;
    props.setLoading(true)
    mainSvg = props.main.mainCanvas.toSVG({
      width:'100%',
      height: '100%',
      viewBox: {          
          x:0,
          y:0,
          width:Math.abs(props.main.mainCanvas.endX-props.main.mainCanvas.startX+50),
          height:Math.abs(props.main.mainCanvas.endY-props.main.mainCanvas.startY+50)
      }
      

    });
      
    var bookmarkedExhibitors = [];

    if(boothList && boothList.length > 0){
      var newBoothList = boothList.filter((booth)=>{
        return booth.bookmark
      })
  
      for(var i = 0; i < newBoothList.length; i++){
        bookmarkedExhibitors.push({company: newBoothList[i]['company'], boothName: newBoothList[i]['name']})
      }
    }

    var url = `/generatepdf`
    var formdata = 
    {
        content:mainSvg,
        bookmarkedExhibitors: bookmarkedExhibitors
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.post(url, data, options).then(res=>{
        const result = res.data;
        if(result.status == "success"){
          download(`${API_URL()}file.pdf`, 'booth.pdf');
          props.setLoading(false)
        }
        else{
          props.setLoading(false)
        }
        
    }).catch(error => {
        console.log("error", error);
        props.setLoading(false)
    })
  }

  const allExhibitorCount = ()=>{
    var count= 0;
    var objects = props.main.mainCanvas.getObjects();
    for(var i = 0; i< objects.length; i++){
      var object = objects[i]
      if((object.get('class')=="booth" || object.get('class')=="booth-group") && object.layer != "deleted" ){
        if(object.sold)
          count++
      }
    }
    return count;
  }
  const featuredCount = ()=>{
    var count= 0;
    if(boothList && boothList.length > 0){
      var newBoothList = boothList.filter((booth)=>{
        return (booth.company_tag_featured)
      })
  
      return newBoothList.length
    }
    return 0;
  }
  const tableCount = ()=>{
    var count= 0;    
    if(props.main.mainCanvas){
      props.main.mainCanvas.forEachObject(function(object) {
        if(object.class=="table"){
          count++
        }
      });
    }
    return count;
  }
  const bookmarkCount = ()=>{
    return props.main.bookmarkList?.length?props.main.bookmarkList?.length:0
  }
  const threeCount = ()=>{
    var count= 0;
    if(boothList && boothList.length > 0){
      var newBoothList = boothList.filter((booth)=>{
        return (booth.company_tag_3dbooth)
      })
  
      return newBoothList.length
    }
    return 0;
  }

  const doSearch = (searchVal)=>{    
    if(!props.openDraw)
      props.setOpenBoothDrawer(true)
    props.setExhibitorSearchKey(searchVal)
    if(searchVal){
      // var filteredBoothList = boothList.filter((booth)=>{
      //   var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
      //   return (str.toLowerCase().includes(searchVal.toLowerCase()))
      // })
      // props.setLeftBoothList(filteredBoothList)
      props.setLeftPage('searched')
    }
    else{
      // props.setLeftBoothList([])
    }
  }

  const handleDrawerToggle = () => {
    props.setOpenBoothList(!props.ui.openBoothList);
  };

  var cartList = props.cart?.carts;
  
  var sales_shopping_cart_show = false
  var sales_booth_list_show = true
  var sales_3d_booths_show = true
  var sales_featured_exhibitors_show = true
  var sales_bookmarks_show = true
  var category_show = true
  var cart_show = false
  if(props.main.authInfo?.SHOPPING_CART  == 'show' || props.main.authInfo?.SALES?.SHOPPING_CART == 'show'){
      if(props.main.cartList?.length)
          cart_show = true
  }
  // cart_show = true
    
  if(props.main.authInfo){
    if(props.main.authInfo.tokenInfo?.SHOPPING_CART == 'hide'){
      sales_shopping_cart_show = false
    }
    else if(props.main.authInfo.tokenInfo?.SHOPPING_CART == 'show'){
      sales_shopping_cart_show = true
    }
    if(props.main.authInfo.SALES?.BOOTH_LIST == 'hide'){
      sales_booth_list_show = false
    }
    if(props.main.authInfo.SALES?.['3D_BOOTHS'] == 'hide'){
      sales_3d_booths_show = false
    }
    if(props.main.authInfo.SALES?.FEATURED_EXHIBITORS == 'hide'){
      sales_featured_exhibitors_show = false
    }
    if(props.main.showSetup?.PUBLIC?.FEATURED_EXHIBITORS == 'hide'){
      sales_featured_exhibitors_show = false
    }
    if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
      sales_bookmarks_show = false
    }
    if(props.main.authInfo.SALES?.CATEGORY == 'hide'){
      category_show = false
    }
    if(props.main.showSetup?.PUBLIC?.PRODUCT_CATEGORIES == 'hide'){
      category_show = false
    }
  }
  var categoryCnt = 0;
  if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
    // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
    console.log("categoryOptions", props.main.categoryOptions)
    for(var i = 0; i < props.main.categoryOptions.length; i++){
      let subCat = props.main.categoryOptions[i]['SUBCAT'];
      if(subCat?.length){
        categoryCnt+= subCat.length
      }
      else{
        categoryCnt++;
      }
    }
    // categoryCnt = props.main.categoryOptions.length
  }

  return (
    <Box pt={1} className="side-home xhbt-side-home" style={{paddingLeft:10}}>
      
      <SearchBox/>
      <List className='xhbt-MuiList-root'>
        {props.main.active_booth_file?.event_home && props.main.active_booth_file?.event_home!="" &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            href={props.main.active_booth_file? `${(props.main.active_booth_file?.event_home?.includes("http")?"":"https://")+props.main.active_booth_file.event_home}`:"#"} target="_blank"
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem}  primary={'Home'} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        {sales_booth_list_show && props.role =='admin' &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>{props.setLeftPage('booths')}}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={'Booth List'} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        {sales_bookmarks_show && props.bookmarkCnt>0 && getTokenPlanner() &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>props.setLeftPage('bookmarks')}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>Bookmarks <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({props.bookmarkCnt?props.bookmarkCnt:0})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        
        {props.role != 'admin' && cart_show &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>{
              props.setLeftPage('sponsor_cart') 
            }}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>Shopping cart <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({Math.max(props.main.cartList?.length-2,0)})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        {props.role != 'admin' && sales_shopping_cart_show &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>{
              const _token = getTokenPlanner();
              if(!_token){
                props.setOpenLogin(true)
                return false;
              }
              props.setLeftPage('cart')
            }}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>Shopping cart <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({cartList?cartList.length:0})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        {sales_3d_booths_show &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>props.setLeftPage('three')}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>3D Booths <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({threeCount()})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        {sales_featured_exhibitors_show &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>props.setLeftPage('features')}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>Featured Exhibitors <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({props.exhibitorFeaturedCnt?props.exhibitorFeaturedCnt:0})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>props.setLeftPage('all')}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>List All Exhibitors <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({props.exhibitorCnt?props.exhibitorCnt:0})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        {category_show&&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>props.setLeftPage('categorylist')}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>Categories <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({categoryCnt})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>   
        }     
      </List>
      
    </Box>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
      cart: state.cart,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) ,
        setShowGrid: value=>dispatch(setShowGrid(value)) ,
        setBoothNumberLayer: value=>dispatch(setBoothNumberLayer(value)) ,
        setBoothOutlineLayer: value=>dispatch(setBoothOutlineLayer(value)) ,
        setAnnotationLayer: value=>dispatch(setAnnotationLayer(value)) ,
        setOriginalLayer: value=>dispatch(setOriginalLayer(value)) ,
        setLeftPage: value=>dispatch(setLeftPage(value)) ,
        setExhibitorSearchKey: value=>dispatch(setExhibitorSearchKey(value)) ,
        setLoading:  data=>dispatch(setLoading(data)),
        setLeftSelectedCategory: value=>dispatch(setLeftSelectedCategory(value)),
        setLeftBoothList:  data=>dispatch(setLeftBoothList(data)),
        setBookmardUpdated:   data=>dispatch(setBookmardUpdated(data)),
        setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
        setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
        setOpenBoothDrawer: value=>dispatch(setOpenBoothDrawer(value)) ,
        setExhibitorList:  data=>dispatch(setExhibitorList(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);