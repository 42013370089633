import * as React from 'react';
import { useEffect,useState, useRef } from 'react';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { setOpenCompanyProfile,  setSnackMsg, setOpenSnack } from '../../actions/ui.js'
import CompanyProfile from './CompanyProfile.js';
import SponsorshipProfile from './SponsorshipProfile.js';
const drawerWidth = Math.min(Math.max(440, Math.round(window.innerWidth*0.33)), window.innerWidth);

var open;
function Index(props) {
  const [zIndex, setzIndex] = React.useState(1004);
  const scrollRef = useRef(null);

  open = props.ui.openCompanyProfile.open || props.ui.openSponsorshipProfile.open;

  useEffect(async () => {
    if((props.ui.openCompanyProfile && props.ui.openCompanyProfile.open) || (props.ui.openSponsorshipProfile && props.ui.openSponsorshipProfile.open)){
      setzIndex(10000)
    }
    else{
      setzIndex(1004)
    }
  }, [props.ui.openCompanyProfile, props.ui.openSponsorshipProfile])
  
  var sales_shopping_cart_show = false
  var sales_left_drawer_show = true
  if(props.main.authInfo){
    if(props.main.authInfo.tokenInfo?.SHOPPING_CART == 'hide'){
      sales_shopping_cart_show = false
    }
    else if(props.main.authInfo.tokenInfo?.SHOPPING_CART == 'show'){
      sales_shopping_cart_show = true
    }
    if(props.main.authInfo.SALES?.LEFT_DRAWER == 'hide'){
      sales_left_drawer_show = false
    }
  }
  return (
    <>    
    {props.main.authInfo.authenticated == 3 && sales_left_drawer_show && Boolean(props.ui.mouseHoverEnable) &&
    <Box className="sideview company-profile-viewer assigned" sx={{ display: 'flex', zIndex:zIndex}}>
      
      <Drawer
        ref={scrollRef}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            minWidth: drawerWidth-12,
            maxWidth: drawerWidth,
            width:'100%',
            boxSizing: 'content-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        className={"full-height-slider "+(props.role == "admin"?"":"top-0")}
      >
        {props.ui.openCompanyProfile?.open &&
        <CompanyProfile groupOptions = {props.boothGroups} role = {props.role}/>
        }
        {props.ui.openSponsorshipProfile?.open &&
        <SponsorshipProfile groupOptions = {props.boothGroups} role = {props.role}/>
        }
      </Drawer>
    </Box>
    }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};
const mapDispatchProps = (dispatch) => {
  return {    
    setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
    setSnackMsg: data=>dispatch(setSnackMsg(data)),
    setOpenSnack: data=>dispatch(setOpenSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
